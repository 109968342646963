<template>
  <ion-page class="page scrollable bg-transparent pb-5">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageHeader class="mt-4" :title="pageTitle" invert />
    <section class="buttons mt-4">
      <ButtonGrid
        :style="{ '--background': '#214163', textTransform: 'unset', '--border-radius': '20px' }"
        :buttons="links"
      />
    </section>
    <section class="position-relative">
      <h2>Popular Fandoms on CharacterHub</h2>
      <Grid :scrollSm="false" :md="4" :sm="3" :xs="2">
        <router-link 
          v-for="(fandom, index) in fandoms"
          :key="index"
          :to="`/fandoms/${fandom.slug}`"
        ><ion-button 
          size="small"
          color="medium"
          class="grid-item fandom-button"
          :title="fandom.name"
        > <div class="fandom-button-inner">{{ fandom.name }}</div>
        </ion-button></router-link>
      </Grid> 
    </section>  
    <section class="mt-3">
      <TitleWithAction
        title="Popular Fan Art"
        class="mb-2"
        action-label="See more fan art"
        :with-action="true"
        @action-clicked="() => {$router.push('/fan-art')}"
      />
      <FanArtGrid :fan-arts="fanArts" />
    </section>  
    <section class="mt-3">
      <h2>Popular Fandom Original Characters (OCs)</h2>
      <InfiniteCharacters
        class="mt-2"
        :characters="ocs"
        :loading="false"
        :paging="{}"
        :hideTypeTag="true"
        :listLayout="true"
        :showCharacterTags="true"
      />
    </section>  
  </ion-page>
</template>
<script lang="ts" setup>
import Breadcrumbs from '@/shared/components/breadcrumbs.vue';
import ButtonGrid from '@/shared/components/button-grid.vue';
import TitleWithAction from '@/shared/components/TitleWithAction.vue';
import PageHeader from '@/shared/components/page-header.vue';
import FanArtGrid from '@/shared/components/fan-art-grid.vue';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import image from '../../../public/images/header_OC_art.png';
import { fetchFandomIndexContent } from '@/shared/actions/public';
import { useChHead } from '~/composables/useChHead';
import { getFeaturedCharacters, getFeaturedVisuals } from '@/shared/actions/characters';
const breadcrumbs = ref([
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Fandoms',
    href: '/fandoms',
    isActive: true,
  },
]);
const {
  public: { baseCDNUrl },
} = useRuntimeConfig();
const fanArts = ref<any[]>();
const ocs = ref<any[]>([]);
const picture = ref(`${baseCDNUrl}${image}`);
const title = ref('Explore Popular Fandoms and Fandom OCs!');
const description = ref(
  'Explore thousands of OC drawings and character designs across fandoms! Browse artwork created by original character artists from all over the world.'
);
const pageTitle = ref('Fandoms on CharacterHub');
const allowCrawlers = ref(true);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);
const allowAiBots = ref(true);
useChHead(pageTitle, title, description, url, picture, allowCrawlers, allowAiBots);
const links = ref<any[]>([
  { label: 'Original Characters', href: '/original-character' },
  { label: 'See More OC Art', href: '/original-character-art' }
]);
const data = ref<any>({});
const fandoms = computed(() => {
  return data.value?.fandoms || [];
});
const loadChars = async () => {
  const { results, ...paging } = await getFeaturedCharacters(1, 20);
  ocs.value = [...ocs.value, ...results.map(({ character }: any) => character)];
};
const loadVisuals = async () => {
  const { results } = await getFeaturedVisuals(undefined, 1, 20);
  fanArts.value = results.map(({ image, character }: any) => ({
    visual_url: image?.image,
    character,
    image,
  }));
  if (fanArts.value?.length)
    picture.value = `${baseCDNUrl}${fanArts.value![0].visual_url}`;
};
const loadFandomIndexContent = async () => {
  data.value = await fetchFandomIndexContent();
};
useAsyncData(async () => {
  await Promise.all([loadFandomIndexContent(), loadChars(), loadVisuals()]);
});
</script>
<style lang="sass" scoped>
.borders
  border-radius: 12px
h1
  margin: 2rem auto
h2
  font-weight: bold
  font-size: 1.25rem
article
  background: rgba(121, 121, 121, 0.2)
  padding: 1rem
  border-radius: 10px
.title
  font-size: 28px
  font-weight: bold
  margin-bottom: 3rem
  text-align: center
.fandom
  border-radius: 16px
  background: rgba(121, 121, 121, 0.2)
  padding: 0.4rem 1rem
  font-size: 0.8rem
  min-width: 100px
  text-align: center
.fanart
  object-fit: cover
  width: 100%
  height: 100%
.fan-art-card
  border-radius: 10px
  height: 300px
.art-link
  font-weight: bold
.art-caption
  text-align: center
.fandom-button
  width: 150px !important
  min-width: 150px !important
  overflow: hidden !important
  --border-radius: 20px !important
.fandom-button-inner
  text-align: left
  line-height: 1rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>